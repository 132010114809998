import { cx, api, rx } from "../../../../api";
import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map as rxmap, filter, tap, ignoreElements } from 'rxjs/operators';
import { formatDateTime, getTimezoneString } from '../../../../misc/misc';
import { resolver } from './resolver';
import { actions } from '../actions';
import { f, fc } from "../../../../../i18n";
import { errorMap } from "../../../actions";

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.idling.request.type),
		switchMap(action =>
			rx(api.reports.idling, action.uri, action.parameters).pipe(
				rxmap(operation => actions.idling.success({ intervals: operation.response() })),
				errorMap(actions.idling.fail),
				takeUntil(action$.pipe(ofType(actions.idling.cancel.type)))
			)
		)
	)
}

const startPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.idling.export.type),
		rxmap(action => {
			const state = state$.value.reports.idling;
			resolver.resolve(state.list, !state.hasMore);
			if (state.hasMore) {
				return actions.idling.request({ uri: action.uri, parameters: state.parameters });
			} else {
				return actions.idling.success({});
			}
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.idling.success.type),
		filter(action => {
			const state = state$.value.reports.idling;
			if (state.exporting) resolver.resolve(action.intervals, !state.hasMore);
			return state.exporting && state.hasMore;
		}),
		rxmap(action => {
			const state = state$.value.reports.idling;
			return actions.idling.request({ uri: state.uri, parameters: state.parameters });
		})
	)
}

const exportCsvEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.idling.exportProgress.type),
		filter(action => action.progress == 100),
		rxmap(action => {
			const state = state$.value.reports.idling;
			const device = state$.value.devices.map[state.uri];
			// report header
			let csv = '"'
				+ fc('report type')
				+ '",'
				+ fc('generated')
				+ ','
				+ fc('timezone')
				+ ','
				+ fc('device')
				+ ','
				+ fc('since')
				+ ','
				+ fc('until')
				+ ',"'
				+ fc('maximum speed') + ', ' + f('units.km/h')
				+ '","'
				+ fc('minimum period')
				+ '"\n';
			csv += fc('idling');  // report type
			csv += ',' + formatDateTime(cx.now()); // generated at
			csv += ',' + getTimezoneString(); // timezone at
			csv += ',"' + (device.denomination() || device.uri) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.since) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.until) + '"';
			csv += ',' + state.parameters.maxSpeed;
			csv += ',' + state.parameters.minPeriod;
			csv += "\n\n";
			// content header
			csv += fc('since')
				+ ',"'
				+  fc('since location')
				+ '","'
				+ fc('since address')
				+ '",'
				+ fc('until')
				+ ',"'
				+ fc('until location')
				+ '","'
				+ fc('until address')
				+ '"\n';
			// content
			state.list.forEach(interval => {
				// since
				const sinceMessage = interval.since;
				csv += '"' + formatDateTime(sinceMessage.generatedAt) + '"';
				csv += ',(' + sinceMessage.latitude + ';' + sinceMessage.longitude + ')';
				let entryKey = resolver.key(sinceMessage.latitude, sinceMessage.longitude);
				let address = resolver.resolved[entryKey]?.getAddress();
				if (address) csv += ',"' + address.format() + '"';
				else csv += ",-";
				// until
				const untilMessage = interval.until;
				if (!untilMessage) csv += ',,,';
				else {
					csv += ',"' + formatDateTime(untilMessage.generatedAt) + '"';
					csv += ',(' + untilMessage.latitude + ';' + untilMessage.longitude + ')';
					entryKey = resolver.key(untilMessage.latitude, untilMessage.longitude);
					address = resolver.resolved[entryKey]?.getAddress();
					if (address) csv += ',"' + address.format() + '"';
					else csv += ",-";
				}
				//
				csv += "\n";
			});
			return actions.idling.exportDone({ csv });
		}),
	)
}

const exportClearEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.idling.exportClear.type),
		tap(() => resolver.clear()),
		ignoreElements()
	);
}

const epic = combineEpics(requestEpic, startPrepareEpic, processPrepareEpic, exportCsvEpic, exportClearEpic);

export { epic };
